@import '../../assets/styles/mixins.scss';

.header,
.content {
   display: flex;
   justify-content: space-between;
}

.header {
   margin-bottom: 4px;
}
