@import '../../assets/styles/mixins.scss';

.modal {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 1000;
   overflow: auto;
   background: rgba(0, 0, 0, 0.25);
   backdrop-filter: blur(4px);

   @include fadeIn(0s);
}

.backdrop {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   cursor: pointer;
   border: none;
   background: none;
}

.wrapper {
   position: relative;
   z-index: 10;
   padding: 10% 30px;
   min-height: 100vh;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}

.heading {
   margin: 0 0 32px;
}

.content {
   position: relative;
   background-color: var(--color-white);
   padding: 30px;
   max-width: 448px;
   width: 100%;
   border-radius: 10px;

   @include responsive('md') {
      padding: 48px;
   }

   @include fadeUpIn(0.25s);

   h2:first-child {
      margin-top: 0;
   }

   img {
      display: block;
      width: 100%;
      height: auto;
   }
}

.close {
   position: absolute;
   top: 10px;
   right: 10px;
   border: none;
   background: none;
   width: 35px;
   height: 35px;
   display: flex;
   justify-content: center;
   align-items: center;
   transition: opacity 0.2s;
   cursor: pointer;

   &:hover {
      opacity: 0.5;
   }

   svg {
      width: 20px;
      height: 20px;
      display: block;
      fill: var(--color-black);
   }
}
