@import '../../assets/styles/mixins.scss';

.title {
   font-size: 18px;
   font-weight: bold;
   margin-bottom: 8px;
   text-transform: capitalize;
}

.footer {
   display: flex;
   justify-content: space-between;
}

.info {
   color: var(--color-grey-text);
   margin-bottom: 4px;
}

.thread {
   margin: 0 -24px;
   padding: 16px 24px;
   text-decoration: none;
   display: block;
   font-weight: normal;
   border-bottom: 1px solid var(--color-white-hover);

   &:last-child {
      border-bottom: none;
   }

   &:hover,
   &.active {
      background-color: var(--color-grey-bg);
   }
}
