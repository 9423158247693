@import '../../../assets/styles/mixins.scss';

.actions {
   gap: 16px;
   justify-content: space-between;
   display: flex;
   margin: 24px 0;

   @include responsive('md') {
      margin: 40px 0;
      justify-content: flex-start;
   }
}

.status {
   text-align: center;
   font-weight: bold;

   p {
      margin-top: 16px;
   }
}

.reasons {
   display: grid;
   grid-template-columns: 1fr;
   gap: 16px;
}
