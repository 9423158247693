@import '../../assets/styles/mixins.scss';

.card {
   border-radius: 4px;
   background-color: var(--color-white);
   border: 1px solid var(--color-white-hover);
   margin-bottom: 32px;
}

.header {
   background-color: var(--color-grey-bg);
   border-bottom: 1px solid var(--color-white-hover);
   display: flex;
   justify-content: space-between;
   padding: 14px 16px;

   @include responsive('md') {
      padding: 20px 24px;
   }

   h2 {
      margin: 0;
   }
}

.title {
   display: flex;
   align-items: center;

   svg {
      width: 20px;
      height: 20px;
      margin-right: 12px;
      fill: var(--color-selected-item);
   }
}

.action {
   display: flex;
   text-decoration: none;
   white-space: nowrap;
   align-items: center;
   color: var(--color-grey-text);
   border: none;
   background: none;

   &:hover {
      text-decoration: underline;
   }

   svg {
      fill: currentColor;
      margin-left: 8px;
      width: 1em;
      height: 1em;
   }
}

.content {
   position: relative;
   color: var(--color-grey-text);
   min-height: 40px;

   @include responsive('md') {
      min-height: 60px;
   }

   strong {
      color: var(--color-black);
      font-size: 15px;
   }
}

.loading {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   display: flex;
   justify-content: center;
   align-items: center;
   backdrop-filter: blur(4px);
}
