@import '../../assets/styles/mixins.scss';

.notice {
   padding: 8px 16px;
   display: flex;
   align-items: center;
   justify-content: center;
   color: var(--color-white);
   background-color: var(--color-black);
   width: 100%;
   font-size: 13px;

   @media print {
      display: none;
   }
}

.noticeText {
   margin-right: 24px;
   text-transform: uppercase;
}
