@import '../../assets/styles/mixins.scss';

.button {
   padding: 12px 20px;
   display: inline-flex;
   gap: 8px;
   text-decoration: none;
   align-items: center;
   justify-content: center;
   border: 1px solid var(--color-selected-item);
   background-color: var(--color-selected-item);
   font-family: var(--font-base);
   color: var(--color-white);
   border-radius: 4px;
   font-size: 13px;
   line-height: 1;
   transition: all 0.3s ease-in-out;

   svg {
      width: 1em;
      height: 1em;
      display: block;
      flex-shrink: 0;
      fill: currentColor;
   }

   &:hover {
      background-color: var(--color-selected-item-bg);
      border-color: var(--color-selected-item-bg);
      color: var(--color-selected-item);
   }

   &.ghost {
      background-color: transparent;
      border-color: var(--color-white-hover);
      color: var(--color-black);

      &:hover {
         background-color: var(--color-white-hover);
         border-color: var(--color-white-hover);
      }
   }

   &.grey {
      background-color: var(--color-grey-text);
      border-color: var(--color-grey-text);

      &:hover {
         color: var(--color-grey-text);
         background-color: var(--color-grey-bg);
         border-color: var(--color-grey-bg);
      }
   }

   &.secondary {
      background-color: var(--color-selected-item-bg);
      border-color: var(--color-selected-item-bg);
      color: var(--color-grey-text);

      &:hover {
         background-color: var(--color-selected-item);
         border-color: var(--color-selected-item);
         color: var(--color-white);
      }
   }

   &.block {
      display: flex;
      width: 100%;
   }

   &.small {
      padding: 8px 14px;
   }

   &[disabled] {
      pointer-events: none;
      opacity: 0.5;
   }
}
