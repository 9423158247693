@import 'mixins.scss';

:root {
   --color-black: #000;
   --color-white: #fff;
   --color-selected-item-bg: rgba(93, 207, 151, 0.16);
   --color-selected-item: #72a7a0;
   --color-white-hover: #e1e1e1;
   --color-grey-bg: #f2f1ef;
   --color-grey-text: #555555;
   --color-secondary: #ff9f00;
   --color-blue: #0084ff;
   --font-base: Arial, Helvetica, sans-serif;

   --color-message-blue: #0084ff;
   --color-message-red: #ff0000;
   --color-message-red-bg: #ffe6e6;
}

* {
   box-sizing: border-box;
   padding: 0;
   margin: 0;
}

html,
body {
   max-width: 100vw;
}

body {
   font-family: var(--font-base);
   font-size: 13px;
   background-color: var(--color-grey-bg);
}

h1,
h2 {
   font-weight: bold;
}

h1 {
   font-size: 28px;
   margin: 24px 0 40px;
}

h2 {
   font-size: 16px;
   margin: 24px 0 16px;

   @include responsive('md') {
      font-size: 18px;
   }
}

h3 {
   font-size: 14px;

   @include responsive('md') {
      font-size: 16px;
   }
}

a {
   color: currentColor;
   text-decoration: underline;

   &:hover {
      text-decoration: none;
   }
}

button {
   cursor: pointer;
   font-family: var(--font-base);
}

input,
textarea,
select {
   border: 1px solid var(--color-white-hover);
   padding: 16px;
   display: block;
   margin-bottom: 32px;
   width: 100%;
   border-radius: 4px;
   font-family: var(--font-base);
}

label {
   display: block;
   margin-bottom: 16px;
   font-size: 16px;
   font-weight: bold;
}
