@import '../../assets/styles/mixins.scss';

.title {
   margin-bottom: 24px;
}

.header {
   border-bottom: 1px solid var(--color-white-hover);
   padding-bottom: 32px;
   margin-bottom: 32px;

   @include responsive('md') {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
   }
}

.expectedDelivery {
   margin-bottom: 24px;

   @include responsive('md') {
      margin-bottom: 0;
   }

   div {
      color: var(--color-grey-text);
      margin-bottom: 4px;
   }

   strong {
      font-size: 15px;
   }
}

.actions {
   display: flex;
   flex-wrap: wrap;
   gap: 16px;
}

.status {
   color: var(--color-grey-text);
}

.details {
   @include responsive('md') {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
   }
}
