@import '../../../assets/styles/mixins.scss';

.elements {
   padding: 16px;
}

.element {
   display: flex;
   align-items: center;
   font-size: 14px;
   padding: 14px 16px;
   color: var(--color-grey-text);
   border-radius: 4px;

   svg {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      margin-right: 12px;
      fill: currentColor;
   }

   &.active {
      background-color: var(--color-grey-bg);
      border-left: 3px solid var(--color-selected-item);
   }

   &.completed {
      color: var(--color-selected-item);
   }
}
