.error {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
   flex-direction: column;

   h1 {
      margin: 0;
   }
}
