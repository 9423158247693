.wrapper {
   position: relative;

   &:hover .tooltip {
      display: block;
   }
}

.tooltip {
   position: absolute;
   bottom: calc(100% + 7px);
   left: 50%;
   transform: translateX(-50%);
   background-color: #000;
   color: #fff;
   padding: 0.5rem;
   border-radius: 0.25rem;
   display: none;
   text-align: center;
   width: 250px;

   &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      border-width: 5px;
      border-style: solid;
      border-color: #000 transparent transparent transparent;
      transform: translateX(-50%);
   }
}
