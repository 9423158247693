@import '../../../assets/styles/mixins.scss';

.actions {
   display: flex;
   gap: 16px;
   justify-content: space-between;

   @include responsive('md') {
      margin: 40px 0;
      justify-content: flex-start;
   }
}
