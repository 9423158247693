.cancelOrderModal {
   label {
      margin-top: 32px;
   }

   textarea {
      min-height: 120px;
   }
}

.heading {
   margin: 0 0 32px;
}
