.loading {
   width: 40px;
   height: 40px;
   position: relative;
   margin: auto;

   &.large {
      width: 120px;
      height: 120px;
   }
}

.ball {
   width: 100%;
   height: 100%;
   border-radius: 50%;
   background-color: var(--color-grey-text);
   opacity: 0.6;
   position: absolute;
   top: 0;
   left: 0;

   animation: bounce 2s infinite ease-in-out;

   &:last-child {
      animation-delay: -1s;
   }
}

@keyframes bounce {
   0%,
   100% {
      transform: scale(0);
      -webkit-transform: scale(0);
   }
   50% {
      transform: scale(1);
      -webkit-transform: scale(1);
   }
}
