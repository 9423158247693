@import '../../assets/styles/mixins.scss';

.tickets {
   display: grid;
   grid-template-columns: 1fr;
   gap: 40px;

   @include responsive('sm') {
      grid-template-columns: 1fr 1fr;
   }

   @include responsive('lg') {
      grid-template-columns: 1fr 1fr 1fr;
   }
}

.title {
   display: flex;
   flex-direction: column;
   align-items: flex-start;

   > * {
      margin-top: 24px;
   }
}
