@import '../../assets/styles/mixins.scss';

.cardline {
   padding: 14px 16px;
   border-bottom: 1px solid var(--color-white-hover);
   display: block;
   text-decoration: none;

   @include responsive('md') {
      padding: 22px 24px;
   }

   &:last-child {
      border-bottom: none;
   }
}

.link {
   &:hover {
      background-color: var(--color-grey-bg);
   }
}
