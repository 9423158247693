@import '../../assets/styles/mixins.scss';

.layout {
   display: flex;
   flex-direction: column;
   width: 100%;

   @include responsive('md') {
      flex-direction: row;
   }
}

.main {
   width: 100%;
   flex-grow: 1;
   display: flex;
   flex-direction: column;
   padding-top: var(--navigation-height);
}

.loading {
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
}
