.reason {
   h3 {
      margin: 24px 0 8px;

      &:first-child {
         margin-top: 0;
      }
   }

   ul {
      padding-left: 20px;
   }
}

.preview {
   display: flex;
   flex-wrap: wrap;
   gap: 40px;
}

.description {
   white-space: pre-wrap;
}

.image {
   width: 200px;
   height: 200px;
   border: 1px solid var(--color-white-hover);
   border-radius: 8px;
   overflow: hidden;

   img {
      width: 100%;
      height: 100%;
      object-fit: cover;
   }
}
