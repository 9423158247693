$breakpoints: (
   'sm': (
      min-width: 637px,
   ),
   'md': (
      min-width: 992px,
   ),
   'lg': (
      min-width: 1200px,
   ),
   'xl': (
      min-width: 1600px,
   ),
);

// ------- Mixins
@mixin responsive($name) {
   // If the key exists in the map
   @if map-has-key($breakpoints, $name) {
      // Prints a media query based on the value
      @media #{inspect(map-get($breakpoints, $name))} {
         @content;
      }
   }
}

@mixin fadeUpIn($delay) {
   opacity: 0;
   animation: 0.5s ease $delay forwards fadeUpIn;

   @keyframes fadeUpIn {
      from {
         opacity: 0;
         transform: translateY(20px);
      }
      to {
         opacity: 1;
         transform: translateY(0);
      }
   }
}

@mixin fadeIn($delay) {
   opacity: 0;
   animation: 0.5s ease $delay forwards fadeIn;

   @keyframes fadeIn {
      from {
         opacity: 0;
      }
      to {
         opacity: 1;
      }
   }
}
