@import '../../assets/styles/mixins.scss';

:root {
   --navigation-height: 60px;

   @include responsive('md') {
      --navigation-height: 80px;
   }
}

.header {
   width: 100%;
   height: var(--navigation-height);
   display: flex;
   align-items: center;
   justify-content: space-between;
   background: var(--color-white);
   border-bottom: 1px solid var(--color-white-hover);
   position: fixed;
   top: 0;
   left: 0;
   z-index: 100;
}

.links {
   display: flex;
   align-items: center;
   height: 100%;
}

.navitem {
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 8px;
   border: none;
   border-left: 1px solid var(--color-white-hover);
   text-decoration: none;
   background: none;
   font-size: 11px;
   white-space: nowrap;
   min-width: 60px;

   @include responsive('md') {
      padding: 16px;
      min-width: 100px;
      font-size: 13px;
   }

   &:hover {
      background-color: var(--color-grey-bg);
   }

   svg {
      flex-shrink: 0;
      width: 16px;
      height: 16px;
      fill: var(--color-black);
      opacity: 0.8;
      margin-bottom: 4px;

      @include responsive('md') {
         margin-bottom: 8px;
         width: 20px;
         height: 20px;
      }
   }

   &.burger {
      @include responsive('md') {
         display: none;
      }
   }
}

.sidebar {
   background: var(--color-white);
   color: var(--color-black);
   border-right: 1px solid var(--color-white-hover);
   width: 100%;
   padding: 100px 16px 16px;
   height: 100vh;
   overflow: auto;
   top: 0;
   left: 0;
   position: fixed;
   transform: translateX(-100%);
   transition: transform 0.3s ease-in-out;
   flex-shrink: 0;
   display: flex;
   flex-direction: column;

   &.open {
      transform: translateX(0);
   }

   @include responsive('md') {
      padding: 160px 16px 16px;
      transform: translateX(0);
      position: sticky;
      width: 250px;
   }

   @include responsive('xl') {
      width: 300px;
   }
}

.logo {
   width: 250px;
   max-width: 100%;
   max-height: 72px;
   object-fit: contain;
   object-position: left;
   display: block;
   object-position: center;
   padding: 16px;

   @include responsive('xl') {
      width: 300px;
   }
}

.link {
   font-size: 14px;
   font-weight: bold;
   display: flex;
   padding: 14px 16px;
   border-radius: 4px;
   text-decoration: none;

   @include responsive('xl') {
      font-size: 16px;
   }

   &:hover,
   &.active {
      background-color: var(--color-grey-bg);
      color: var(--color-black);

      .count {
         background-color: var(--color-white);
      }

      svg {
         fill: var(--color-selected-item);
         opacity: 1;
      }
   }

   svg {
      width: 20px;
      height: 20px;
      display: block;
      margin-right: 16px;
      flex-shrink: 0;
      fill: var(--color-black);
      opacity: 0.8;
   }
}

.count {
   font-size: 10px;
   width: 20px;
   height: 20px;
   border-radius: 4px;
   background-color: var(--color-grey-bg);
   display: flex;
   align-items: center;
   justify-content: center;
   margin-left: auto;
}

.menu {
   margin-bottom: auto;
}

.support {
   padding: 24px 0;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   text-align: center;

   img {
      width: 48px;
      height: 48px;
      object-fit: cover;
      margin-bottom: 16px;
   }

   strong {
      margin-bottom: 8px;
   }

   a {
      color: var(--color-grey-text);
      text-decoration: none;
      margin-bottom: 4px;
      font-weight: 500;
   }
}

.openingHours {
   font-weight: 500;
   color: var(--color-grey-text);
}

.openNow {
   width: 8px;
   height: 8px;
   border-radius: 50%;
   background-color: #008800;
   display: inline-block;
   margin-right: 4px;
}
