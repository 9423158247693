@import '../../assets/styles/mixins.scss';

.header {
   display: flex;
   justify-content: space-between;
   padding-bottom: 4px;
   align-items: center;
   gap: 30px;
   flex-wrap: wrap;
}

.name {
   flex-grow: 2;
}

.discounted {
   text-decoration: line-through;
}
