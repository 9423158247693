.backbutton {
   display: flex;
   align-items: center;
   text-decoration: none;

   color: var(--color-grey-text);

   svg {
      width: 10px;
      height: 10px;
      margin-right: 8px;
      fill: currentColor;
   }

   &:hover {
      text-decoration: underline;
   }
}
