@import '../../assets/styles/mixins.scss';

.contact {
   @include responsive('md') {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 80px;
   }
}

.contactInfo {
   > div:first-child {
      h2 {
         @include responsive('md') {
            margin-top: 0;
         }
      }
   }
}
