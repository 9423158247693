@import '../../assets/styles/mixins.scss';

.header {
   display: flex;
   justify-content: space-between;
   margin-bottom: 4px;
}

.content {
   display: flex;
   justify-content: space-between;
}
