@import '../../assets/styles/mixins.scss';

.back {
   margin: 24px 16px 0;

   @include responsive('md') {
      display: none;
   }

   @media print {
      display: none;
   }
}

.messages {
   background-color: var(--color-white);
   margin: 16px;
   border-radius: 8px;
   border: 1px solid var(--color-white-hover);

   @include responsive('md') {
      border: none;
      border-radius: 0;

      margin: 0;
      flex-grow: 1;
      display: flex;
   }
}

.header {
   align-items: center;
   justify-content: space-between;
   margin-bottom: 24px;

   h1 {
      margin: 0 0 16px;
   }

   @include responsive('md') {
      display: flex;
      h1 {
         margin: 0;
      }
   }
}

.threads {
   padding: 32px 24px;

   &.mobileHidden {
      display: none;
   }

   @include responsive('md') {
      border-right: 1px solid var(--color-white-hover);

      width: 40%;
      flex-shrink: 0;

      &.mobileHidden {
         display: block;
      }
   }
}

.message {
   flex-grow: 1;
}
