@import '../../assets/styles/mixins.scss';

.progess {
   display: flex;
   flex-direction: column;
   border: 1px solid var(--color-white-hover);
   border-radius: 8px;
   background-color: var(--color-white);
   margin-top: 8px;
   margin-bottom: 32px;

   @include responsive('md') {
      flex-direction: row;
   }
}

.step {
   position: relative;
   display: flex;
   align-items: center;
   padding: 16px;
   flex-grow: 1;
   border-bottom: 1px solid var(--color-white-hover);
   flex: 1;

   &:last-child {
      border-bottom: none;
   }

   @include responsive('md') {
      padding: 24px 8px;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      border-bottom: none;
      border-right: 1px solid var(--color-white-hover);

      &:last-child {
         border-right: none;
      }
   }

   strong {
      font-size: 16px;
   }

   svg {
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      fill: #008800;
      margin-right: 8px;

      @include responsive('md') {
         margin-right: 0;
         margin-bottom: 12px;
      }
   }

   &.disabled {
      opacity: 0.7;
      color: var(--color-grey-text);

      svg {
         fill: var(--color-grey-text);
      }
   }
}

.description {
   margin-top: 4px;
   color: var(--color-grey-text);
   margin-left: auto;
   padding-left: 8px;
   text-align: right;

   @include responsive('md') {
      margin-left: 0;
      text-align: center;
      padding-left: 0;
   }
}

.arrow {
   position: absolute;
   transform: translate(0, -50%) rotate(90deg);
   width: 24px;
   height: 24px;
   border-radius: 50%;
   border: 1px solid var(--color-white-hover);
   background-color: var(--color-white);
   display: flex;
   align-items: center;
   justify-content: center;
   top: 100%;
   left: 0;
   right: 0;
   margin: 0 auto;

   @include responsive('md') {
      left: auto;
      top: 50%;
      transform: translate(50%, -50%);
   }

   svg {
      margin: 0;
      width: 10px;
      height: 10px;
   }
}
