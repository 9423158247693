@import '../../assets/styles/mixins.scss';

.loading {
   width: 100%;
   display: flex;
   align-items: center;
   padding: 32px 0;
}

.header {
   font-size: 24px;
   font-weight: bold;
   border-bottom: 1px solid var(--color-white-hover);
   padding: 16px;
   text-transform: capitalize;
   display: flex;
   justify-content: space-between;
   align-items: flex-end;

   @include responsive('md') {
      padding: 24px;
   }
}

.subject {
   font-weight: normal;
   font-size: 16px;
   color: var(--color-grey-text);
}

.print {
   display: none;

   @include responsive('md') {
      display: block;
   }
}

.messages {
   padding: 16px;

   @include responsive('md') {
      padding: 32px;
   }
}

.message {
   display: block;
   color: var(--color-black);
   padding: 32px 0 0;
   position: relative;
   border-bottom: 1px solid var(--color-white-hover);
}

.from {
   margin-top: 32px;
   display: flex;
   font-weight: bold;
   color: var(--color-grey-text);

   &.sent {
      justify-content: flex-end;
   }

   span {
      display: block;
      padding: 4px 8px;
      line-height: 1;
      border-radius: 4px;
      background-color: var(--color-grey-bg);
   }
}

.attachment {
   display: flex;
   align-items: center;
   margin-bottom: 16px;
   text-decoration: none;
   min-height: 50px;

   svg {
      width: 16px;
      height: 16px;
      margin-right: 16px;
      margin-left: 16px;
      fill: currentColor;
   }

   img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      margin-right: 16px;
      border-radius: 4px;
   }

   &:hover {
      background-color: var(--color-grey-bg);
      border-radius: 4px;
   }
}

.body {
   margin-bottom: 32px;
}
