.loadmore {
   background: none;
   border: none;
   margin-top: 8px;
   display: flex;
   align-items: center;
   text-decoration: underline;
   color: var(--color-grey-text);

   &:hover {
      text-decoration: none;
   }

   svg {
      width: 12px;
      height: 8px;
      flex-shrink: 0;
      margin-left: 4px;
      fill: currentColor;
   }
}
