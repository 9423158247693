.table {
   width: 100%;
   border-collapse: collapse;
   border-spacing: 0;

   tr {
      td {
         padding-bottom: 8px;
         color: var(--color-black);
         font-size: 13px;
         &:last-child {
            font-weight: bold;
         }
      }

      &:last-child {
         td {
            padding-bottom: 0;
         }
      }
   }
}
