@import '../../../assets/styles/mixins.scss';

.actions {
   gap: 16px;
   justify-content: space-between;
   display: flex;
   margin: 24px 0;

   @include responsive('md') {
      margin: 40px 0;
      justify-content: flex-start;
   }
}

.label {
   margin-top: 24px;

   &:first-child {
      margin-top: 0;
   }
}

.content {
   @include responsive('md') {
      display: grid;
      gap: 40px;
      grid-template-columns: 1fr 300px;
   }
}

.form {
   flex-grow: 1;
   max-width: 600px;

   label {
      color: var(--color-black);
   }
}

.check {
   display: flex;
   font-weight: normal;
   font-size: 13px;
   margin-bottom: 8px;

   input {
      margin: 0 8px 0 0;
      width: 16px;
      height: 16px;
   }

   &:last-child {
      margin-bottom: 32px;
   }
}

.actions {
   gap: 16px;
   justify-content: space-between;
   display: flex;
   margin: 24px 0;

   @include responsive('md') {
      margin: 40px 0;
      justify-content: flex-start;
   }
}

.content {
   @include responsive('md') {
      display: flex;
   }

   textarea {
      margin-bottom: 16px;
   }
}

.form {
   flex-grow: 1;
   max-width: 600px;
}

.upload {
   position: relative;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   background-color: var(--color-white);
   border: 1px solid var(--color-white-hover);
   border-radius: 8px;
   padding: 48px 24px;

   h3 {
      margin: 24px 0;
      color: var(--color-grey-text);
   }

   > svg {
      width: 64px;
      height: 64px;
      fill: var(--color-white-hover);
   }

   input {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      cursor: pointer;
      opacity: 0;
   }
}

.preview {
   display: flex;
   gap: 16px;
   margin-top: 24px;
   flex-wrap: wrap;
}

.previewItem {
   border: none;
   background: none;
   border-radius: 8px;
   overflow: hidden;
   position: relative;

   &:hover {
      svg {
         opacity: 1;
      }
   }

   img {
      width: 80px;
      height: 80px;
      object-fit: cover;
      object-position: center;
   }

   svg {
      background-color: var(--color-white);
      border-radius: 50%;
      padding: 4px;
      position: absolute;
      top: 4px;
      right: 4px;
      width: 24px;
      height: 24px;
      opacity: 0.5;
   }
}
